import React, {
  ChangeEvent,
  FC,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import uk from "date-fns/locale/uk";
import "react-datepicker/dist/react-datepicker.css";

import { LanguageContext, UserContext } from "../../../contexts";

import { TContactsBlockProps } from "./types";
import { Input, LabelField, Select } from "../../../components/Form";
import { Translater } from "../../../config";
import "./restaurants.scss";
import { Title } from "../../../components/Fonts";
import { IOption } from "../../../types/items";

registerLocale("uk", uk); // register it with the name you want

const ContactsBlock: FC<TContactsBlockProps> = ({
  form,
  setForm,
  isValid,
  setAddress,
  startDate,
  startDateDelivery,
  setStartDateDelivery,
  endDateDelivery,
  setEndDateDelivery,
  endDate,
  setStartDate,
  setEndDate,
  isClicked,
  address,
  valueLang,
  setCoordinates,
  inputHandler,
}) => {
  const { language } = useContext(LanguageContext);
  const { access } = useContext(UserContext);
  // const [value, setValue] = useState<string>("");

  const { ref }: any = usePlacesWidget({
    language: "en",
    apiKey: "AIzaSyDcIiHvYFLdxwXXBohWEtBFr_EDYl2UEnI",
    options: {
      types: ["address"],
    },
    // inputAutocompleteValue: value,
    onPlaceSelected: (place) => {
      setAddress &&
        setAddress([
          { lang: "uk", value: ref.current.value },
          { lang: "en", value: place.formatted_address || "" },
        ]);
      // setValue && setValue(place.formatted_address || "");
      setCoordinates &&
        setCoordinates({
          lon: place?.geometry?.location?.lng()
            ? place?.geometry?.location?.lng().toString()
            : "",
          lat: place?.geometry?.location?.lat()
            ? place?.geometry?.location?.lat().toString()
            : "",
        });
    },
  });

  const selectOptions = [
    {
      value: [
        { value: "all", label: "Приймати - замовлення приймаються" },
        { value: "not", label: "Не приймати - замовлення НЕ приймаються ніде" },
        {
          value: "notApp",
          label: "Не приймати в додатку - замовлення НЕ приймаються в додатку",
        },
        {
          value: "notWeb",
          label: "Не приймати на сайті - замовлення НЕ приймаються на сайті",
        },
      ],
      lang: "uk",
    },
    {
      value: [
        { value: "all", label: "Accept - orders are accepted" },
        {
          value: "not",
          label: "Do not accept - orders are NOT accepted anywhere",
        },
        {
          value: "notApp",
          label:
            "Do not accept in the application - orders are NOT accepted in the application",
        },
        {
          value: "notWebb",
          label:
            "Do not accept on the website - orders are NOT accepted on the website",
        },
      ],
      lang: "en",
    },
  ];

  // useEffect(() => {
  //   const isTitleInArr = address.find((item) => item.lang == valueLang)
  //     ? true
  //     : false;

  //   if (isTitleInArr) {
  //     const name = address.map((item) => {
  //       if (item.lang == valueLang) {
  //         return { value: value, lang: valueLang };
  //       }
  //       return item;
  //     });

  //     setAddress && setAddress(name);

  //     return;
  //   }

  //   const name = address?.concat({
  //     value: value,
  //     lang: valueLang as string,
  //   });

  //   setAddress && setAddress(name);

  //   return;
  // }, [value]);

  return (
    <Fragment>
      <LabelField
        fontSize="small"
        label={Translater.TableTitles.email[language.slug]}
      >
        <Input
          name="email"
          value={form.email}
          isValid={isClicked ? !!form.email : true}
          placeholder={Translater.Placeholders.minThree[language.slug]}
          inputHandler={inputHandler}
        />
      </LabelField>
      <LabelField
        fontSize="small"
        label={Translater.TableTitles.phone[language.slug]}
      >
        <Input
          name="phone"
          value={form.phone}
          isValid={isClicked ? !!form.phone : true}
          placeholder={Translater.Placeholders.minThree[language.slug]}
          inputHandler={inputHandler}
        />
      </LabelField>
      <LabelField
        fontSize="small"
        label={Translater.TableTitles.facebook[language.slug]}
      >
        <Input
          name="facebook"
          value={form.facebook}
          placeholder={Translater.Placeholders.tapsLinks[language.slug]}
          inputHandler={inputHandler}
        />
      </LabelField>
      <LabelField
        fontSize="small"
        label={Translater.TableTitles.instagram[language.slug]}
      >
        <Input
          name="instagram"
          value={form.instagram}
          placeholder={Translater.Placeholders.tapsLinks[language.slug]}
          inputHandler={inputHandler}
        />
      </LabelField>
      <LabelField
        fontSize="small"
        label={Translater.TableTitles.telegram[language.slug]}
      >
        <Input
          name="telegram"
          value={form.telegram}
          placeholder={Translater.Placeholders.tapsLinks[language.slug]}
          inputHandler={inputHandler}
        />
      </LabelField>
      <LabelField
        fontSize="small"
        label={Translater.TableTitles.addressLabel[language.slug]}
      >
        <input
          key={valueLang}
          name="value"
          className={`google-autocomplete`}
          ref={ref}
          defaultValue={
            address?.find((item) => item?.lang === valueLang)?.value
          }
          placeholder={Translater.Placeholders.enterAddress[language.slug]}
        />
      </LabelField>

      <Title title={Translater.TableTitles.workTime[language.slug]} />

      <LabelField
        fontSize="small"
        label={Translater.TableTitles.startTime[language.slug]}
      >
        <DatePicker
          selected={new Date(startDate ?? "") ?? new Date()}
          onChange={(date: any) => setStartDate && setStartDate(date)}
          showTimeSelect
          className={`date-picker`}
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          name="startDate"
          locale="uk"
          dateFormat="HH:mm"
        />
      </LabelField>
      <LabelField
        fontSize="small"
        label={Translater.TableTitles.endTime[language.slug]}
      >
        <DatePicker
          selected={new Date(endDate ?? "") ?? new Date()}
          onChange={(date: any) => setEndDate && setEndDate(date)}
          showTimeSelect
          showTimeSelectOnly
          name="endDate"
          className={`date-picker`}
          timeIntervals={15}
          timeCaption="Time"
          locale="uk"
          dateFormat="HH:mm"
        />
      </LabelField>

      <Title title={Translater.TableTitles.orderAcceptTime[language.slug]} />

      <LabelField
        fontSize="small"
        label={Translater.TableTitles.startTime[language.slug]}
      >
        <DatePicker
          selected={new Date(startDateDelivery ?? "") ?? new Date()}
          onChange={(date: any) =>
            setStartDateDelivery && setStartDateDelivery(date)
          }
          showTimeSelect
          className={`date-picker`}
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          name="startDateDelivery"
          locale="uk"
          dateFormat="HH:mm"
        />
      </LabelField>
      <LabelField
        fontSize="small"
        label={Translater.TableTitles.endTime[language.slug]}
      >
        <DatePicker
          selected={new Date(endDateDelivery ?? "") ?? new Date()}
          onChange={(date: any) =>
            setEndDateDelivery && setEndDateDelivery(date)
          }
          showTimeSelect
          showTimeSelectOnly
          name="endDateDelivery"
          className={`date-picker`}
          timeIntervals={15}
          timeCaption="Time"
          locale="uk"
          dateFormat="HH:mm"
        />
      </LabelField>

      <LabelField
        fontSize="small"
        label={Translater.TableTitles.orderAccept[language.slug]}
      >
        <Select
          name="hasDelivery"
          value={form?.hasDelivery}
          options={
            selectOptions.find((item) => item.lang == language.slug)
              ?.value as IOption[]
          }
          selectHandler={(e: ChangeEvent<HTMLSelectElement>) => {
            setForm({ ...form, hasDelivery: e.target.value });
          }}
        />
      </LabelField>
    </Fragment>
  );
};

export default ContactsBlock;
